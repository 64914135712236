import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function FormRow(props: BoxProps) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box className="FormRow-root" sx={{ display: 'flex', mx: -1, '& > div': { mx: 1 }, ...sx }} {...boxProps}>
      {children}
    </Box>
  );
}

export default memo(FormRow);

import Box, { BoxProps } from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

export type FakeInputProps = BoxProps & {
  label: string;
  error: boolean;
  helperText?: string;
};

export function FakeInput(props: FakeInputProps) {
  const { label, children, sx, error, helperText, ...boxProps } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        mb: 2.75,
        ...sx,
      }}
      {...boxProps}
    >
      <FormControl
        className="FakeInput-root"
        sx={{
          flex: 1,
          border: 1,
          borderColor: error ? 'error.main' : 'grey.400',
          borderRadius: 1,
          px: 1.75,
          position: 'relative',
        }}
      >
        <InputLabel shrink={true} sx={{ bgcolor: 'background.paper', px: '5px' }}>
          {label}
        </InputLabel>
        {children}
      </FormControl>
      {error && <FormHelperText sx={{ mx: 1.75 }}>{helperText}</FormHelperText>}
    </Box>
  );
}
